import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import Section from '~components/Section'
import RichText from '~components/RichText'
import Image from '~components/Image'
import { mobile, tablet, desktop, HeaderImageContainer, ImageSlide } from '~styles/global'
import Button from '~components/Button'
import { Edge } from '~components/Svg'
import Slider from '~components/Slider'
import YouTubeVideo from '~components/YouTubeVideo'
import ProgramTile from '~components/ProgramTile'
import { StaticImage } from 'gatsby-plugin-image'
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import ImageWithShadow from '~components/ImageWithShadow'
import AnimateInView from '~components/AnimateInView'

const IndexPage = ({ data }) => {
	const { page, programIndex } = data
	const [currentSlide, setCurrentSlide] = useState(0)
	const [showProviderDetails, setShowProviderDetails] = useState(false)

	return (
		<>	
			<Seo 
				title={page.title}
				metaTitle={page.seo?.metaTitle}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			<Header>
				<Illustrations>
					<Waves>
						<StaticImage 
							src='../images/illustrations/Waves.png' 
							css={css`width: 100%;`} 
							alt='waves illustration'
							placeholder="none"
						/></Waves>
					<Sun>
						<StaticImage 
							src='../images/illustrations/Sun-(no-face).png' 
							css={css`width: 100%;`} 
							alt='sun illustration'
							placeholder="none"
						/></Sun>
				</Illustrations>
				<HeaderContent>
					<Title>{page.headerTitle}</Title>
					<IntroText className='h2'>
						<RichText content={page.introText}/>
					</IntroText>
				</HeaderContent>
				<ImageCol>
					<HeaderImageContainer>
						<IllustrationsMobile>
							<Waves>
								<StaticImage 
									src='../images/illustrations/Waves.png' 
									css={css`width: 100%;`} 
									alt='waves illustration'
									placeholder="none"
								/></Waves>
							<Sun>
								<StaticImage 
									src='../images/illustrations/Sun-(no-face).png' 
									css={css`width: 100%;`} 
									alt='sun illustration'
									placeholder="none"
								/></Sun>
						</IllustrationsMobile>
						<ImageWithShadow image={page.headerImage}/>
					</HeaderImageContainer>
				</ImageCol>
			</Header>
			<ProviderSection>
				<ProviderContainer>
					<ProviderImage image={page.providerLogo}/>
					<ProviderContent>
						<ProviderText>{page.providerTitle}</ProviderText>
						<ProviderButton 
							color='blue' 
							onClick={() => setShowProviderDetails(!showProviderDetails)}
						>
							{page.providerButtonText}
						</ProviderButton>
					</ProviderContent>
					<SlideDown>
						{showProviderDetails && 
								<ProviderDetails>
									<RichText content={page.providerText}/>
								</ProviderDetails>
						}
					</SlideDown>
				</ProviderContainer>
			</ProviderSection>
			<RoughEdge>
				<EdgeContainer>
					<Edge />
				</EdgeContainer>
			</RoughEdge>
			<AboutSliderSection>
				<AboutSliderContainer>
					<AboutSlider
						onChange={slideNumber => setCurrentSlide(slideNumber)}
					>
						{page.aboutSlider?.slides?.map((slide, i) => (
							<ImageSlide key={`slide${i}`} image={slide} aspectRatio={1.46}/>
						))}
					</AboutSlider>
					<AboutSlideTitle>
						<RichText content={page.aboutSlider?.slides[currentSlide].caption} />
					</AboutSlideTitle>
				</AboutSliderContainer>
				<AboutContent>
					<AboutTitle>
						{page.aboutTitle}
					</AboutTitle>
					<AboutText>
						<RichText content={page.aboutText}/>
					</AboutText>
					<AboutButton link={page.aboutButton?.link} color={page.aboutButton?.color}>
						{page.aboutButton?.text}
					</AboutButton>
				</AboutContent>
			</AboutSliderSection>
			<VideoSection>
				<Video content={page.featuredVideo}/>
			</VideoSection>
			<BirdIllustration>
				<BirdContainer>
					<AnimateInView variants={{
						visible: { x: 0, opacity: 1 },
						hidden: { x: 100, opacity: 0 }
					}}>
						<Bird>
							<StaticImage 
								src='../images/illustrations/Birdy.png' 
								css={css`width: 100%;`} 
								alt='Bird illustration'
								placeholder="none"
							/>
						</Bird>
					</AnimateInView>
				</BirdContainer>
			</BirdIllustration>
			<ProgramsSliderSection>
				<ProgramsTitle>{page.featuredProgramsTitle}</ProgramsTitle>
				<ProgramsSlider large>
					{page.featuredPrograms.map((program, i) => (
						<ProgramSlide hoverTilt key={program.title} program={program}/>
					))}
				</ProgramsSlider>
				<MobilePrograms>
					{page.featuredPrograms.slice(0, 3).map((program, i) => (
						<MobileProgramTile key={program.title} program={program}/>
					))}	
					<SeeAllButton color='green' link={{
						linkType: 'internal',
						document: {
							slug: programIndex.slug
						}
					}}>See all events</SeeAllButton>
				</MobilePrograms>
			</ProgramsSliderSection>
			<DataViewer data={page} name="page"/>
		</>
	)
}

const Header = styled(Section)`
	margin-top: var(--l);
	margin-bottom: var(--l);
	position: relative;
`
const Illustrations = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	pointer-events: all;
	${mobile}{
		display: none;
	}
`
const IllustrationsMobile = styled.div`
	display: none;
	${mobile}{
		display: block;
	}
`
const Waves = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 45%;
	z-index: 2;
	@media (max-width: 1240px){
		bottom: -80px;
	}
	${tablet}{
		bottom: -40px;
	}
	${mobile}{
		bottom: 0px;
		width: 63%;
		transform: translateY(calc((var(--l) / 2) + 10px)) translateX(-2vw);
	}
`
const Sun = styled.div`
	position: absolute;
	top: 0;
	right: calc(var(--l) + 40px);
	width: 19%;
	z-index: 2;
	transform: translateY(-8vw);
	${desktop}{
		width: 22.5%;
		transform: translateY(-13vw);
	}
	${mobile}{
		right: 14px;
    width: 39%;
    transform: translateY(-25vw);
	}
`
const HeaderContent = styled.div`
	grid-column: span 4;
	${mobile}{
		grid-column: span 12;
	}
`
const Title = styled.h2`
	margin-bottom: var(--l);
	color: var(--blue);
`
const IntroText = styled.div`
	margin-bottom: var(--xl);
	max-width: 500px;
	${mobile}{
		max-width: 340px;
	}
`
const ImageCol = styled.div`
	grid-column: 5/13;
	${mobile}{
		grid-column: span 12;
		margin-top: 19px;
	}
`
const ProviderSection = styled(Section)`
	padding: var(--xl) 0;
	background-color: var(--white);
`
const ProviderContainer = styled.div`
	grid-column: span 12;
	margin: 0 auto;
	max-width: 385px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
`
const ProviderImage = styled(Image)`
	max-width: 161px;
	width: 160px;
	margin-right: var(--l);
	${mobile}{
		margin-right: var(--s);
	}
`
const ProviderContent = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
`
const ProviderText = styled.div`
	grid-column: span 12;
`
const ProviderButton = styled(Button)`
	margin-top: auto;	
`
const ProviderDetails = styled.div`
	margin: var(--l) 0;
	width: 100%;
`
const RoughEdge = styled.div`
	height: 80px;
	color: var(--lightBlue);
	position: relative;
	background-color: var(--white);
	margin-top: -40px;
	margin-bottom: -40px;
`
const EdgeContainer = styled.div`
	position: absolute;
	top: 5px;
	width: 100%;
	overflow: hidden;
	height: 80px;
	> svg {
		width: 100%;
		min-width: 1440px;
		left: 50%;
		position: absolute;
		transform: translateX(-50%);
	}
`
const AboutSliderSection = styled(Section)`
	margin: var(--xl) 0 var(--l);
`
const AboutSliderContainer = styled.div`
		grid-column: span 6;
		${mobile}{
			grid-column: span 12;
			margin-bottom: var(--l);
		}
`
const AboutSlider = styled(Slider)`
`
const AboutSlideTitle = styled.div`
`
const AboutContent = styled.div`
	grid-column: 8/13;
	align-self: center;
	max-width: 600px;
	${mobile}{
		grid-column: span 12;
	}
`
const AboutTitle = styled.h3`
	margin-bottom: var(--m);
`
const AboutText = styled.div`
	margin-bottom: var(--m);
`
const AboutButton = styled(Button)`
`
const VideoSection = styled(Section)`
	padding: var(--xl) 0;
	background-color: var(--white);
`
const Video = styled(YouTubeVideo)`
	grid-column: 3/11;
	${mobile}{
		grid-column: span 12;
	}
`
const BirdIllustration = styled.div`
	height: 0px;
	position: relative;
`
const BirdContainer = styled.div`
	height: 14vw;
	max-width: 100vw;
	overflow: hidden;
	position: absolute;
	width: 100%;
	z-index: 2;
	transform: translateY(-50%);
	pointer-events: none;
	${mobile}{
		height: 20vw;
		transform: translateY(-50%);
	}
`
const Bird = styled.div`
	position: absolute;
	top: 0;
	z-index: 2;
	width: 31%;
	right: calc(4% + 40px);
	max-width: 580px;
	${mobile}{
    width: 45%;
    right: 0;
		transform: translateX(20px);
	}
}
`
const ProgramsSliderSection = styled(Section)`
	margin: var(--xl) 0;
	overflow: hidden;
	${mobile}{
		overflow: visible;
	}
`
const ProgramsTitle = styled.h3`
	grid-column: span 12;
	text-align: center;
	margin-bottom: var(--l);
	max-width: 100%;
`
const ProgramsSlider = styled(Slider)`
	grid-column: span 12;
	.flickity-viewport{
		overflow: visible
	}
	${mobile}{
		display: none;
	}
`
const ProgramSlide = styled(ProgramTile)`
	width: 31.6%;
	margin-right: 60px;
`
const MobilePrograms = styled.div`
	display: none;
	${mobile}{
		display: block;
		grid-column: 2/12;
		margin: 0 -12px;
		text-align: center;
	}
`
const MobileProgramTile = styled(ProgramTile)`
	text-align: left;
	:not(:last-of-type){
		margin-bottom: var(--xl);
	}
`
const SeeAllButton = styled(Button)`
	grid-column: span 12;
	justify-self: center;
`

export const query = graphql`
  query HomeQuery {
    page: sanityHomePage {
      title
			seo{
				...seo
			}
			headerTitle
			introText: _rawIntroText(resolveReferences: {maxDepth: 5})
			headerImage {
				...imageWithAlt
			}
			providerTitle
			providerText: _rawProviderText(resolveReferences: {maxDepth: 5})
			providerButtonText
			providerLogo {
				...imageWithAlt
			}
			aboutSlider {
				slides {
					...imageWithCaption
				}
			}
			aboutTitle
			aboutText: _rawAboutText(resolveReferences: {maxDepth: 5})
			aboutButton {
				...button
			}
			featuredVideo{
				youTubeUrl
      	placeholderImage {
					...imageWithAlt
				}
			}
			featuredProgramsTitle
			featuredPrograms {
				slug {
					current
				}
				title
				type
				time
				headerImage {
					...imageWithAlt
				}
			}
    }
		programIndex: sanityProgramIndex {
			slug {
				current
			}
		}
  }
`

IndexPage.propTypes = {
	data: PropTypes.object,
}

export default IndexPage
